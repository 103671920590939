import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/index"
  },
  {//首页
    path: '/index',
    name: 'Index',
    component: () => import('../views/Index.vue')

  },
  {//新闻
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue')

  },
  {//活动
    path: '/project',
    name: 'Project',
    component: () => import('../views/Project.vue')

  },
  {//资料库
    path: '/community',
    name: 'Community',
    component: () => import('../views/Community.vue')

  },
  {//合作伙伴
    path: '/sponsor',
    name: 'Sponsor',
    component: () => import('../views/Sponsor.vue')

  },
  {//新闻/活动/资料库 详情页
    path: '/desc',
    name: 'Desc',
    component: () => import('../views/Desc.vue')

  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})

export default router
