import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang:'zh',
    title:"",
    enTitle:""
  },
  getters: {
  },
  mutations: {
    changLang(state,lang){
      console.log(lang)
      state.lang=lang;
    },
    titCon(state,obj){
      console.log(obj)
      state.title=obj.title;
      state.enTitle=obj.enTitle
       
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [//插件
  createPersistedState({
      storage: window.sessionStorage,
      key: "store",
      render(state) {
          return { ...state };
      }
  })
]
})
