<template>
  <footer class="base-container footer-container">
    <section class="commonhr1 commonhr"></section>
    <div class="base-row base-pc-box footer-logo">
      <div class="base-col-2">
        <div class="footer-logo-box">
          <router-link to="/">
            <img src="../../assets/img/logo.png" alt="" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="base-row base-phone-box footer-logo">
      <div class="base-col-2">
        <div class="footer-logo-box">
          <router-link to="/">
            <img src="../../assets/img/logo.png" alt="" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="base-row base-pc-box">
      <div class="base-col-3 tip-1">
        <div class="horizontal-layout-box">
          <p v-if="lang == 'zh'">了解双碳目标，支持气候行动</p>
          <p v-if="lang == 'zh'">共创碳中和美好未来</p>
          <p v-if="lang == 'en'">
            Understand the dual carbon goals, support climate action, and create
            a carbon neutral future.
          </p>
        </div>
      </div>
      <div class="base-col"></div>
      <div class="base-col connect-box">
        <div class="horizontal-layout-box" v-if="lang == 'zh'">
          <p><a>气候行动周</a></p>
          <p><a target="_blank" href="https://www.wwfchina.org/about?id=40&type=1" rel="noopener noreferrer">隐私政策</a></p>
          <p><a target="_blank" href="https://www.wwfchina.org/about?id=38&type=1" rel="noopener noreferrer">联系我们</a></p>
        </div>
        <div class="horizontal-layout-box" v-if="lang == 'en'">
          <p><a>Climate Action Week</a></p>
          <p><a target="_blank" href="https://www.wwfchina.org/about?id=40&type=1" rel="noopener noreferrer">Privacy
              Policy</a></p>
          <p><a target="_blank" href="https://www.wwfchina.org/about?id=38&type=1" rel="noopener noreferrer">Contact
              us</a></p>
        </div>
      </div>
      <div class="base-col"></div>
      <div class="base-col connect-box">
        <div class="horizontal-layout-box" v-if="lang == 'zh'">
          <p><a>关注我们</a></p>
          <p><a target="_blank" href="https://weibo.com/p/1006061702771281" rel="noopener noreferrer">微博</a></p>
          <p><a class="qrcode">
              微信
              <img src="../../assets/img/weixin.png">
            </a></p>
        </div>
        <div class="horizontal-layout-box" v-if="lang == 'en'">
          <p><a>About us</a></p>
          <p><a target="_blank" href="https://weibo.com/p/1006061702771281" rel="noopener noreferrer">WeiBo</a></p>
          <p><a class="qrcode">
              WeChat
              <img src="../../assets/img/weixin.png">
            </a></p>
        </div>
      </div>
      <div class="base-col connect-box">
        <div class="horizontal-layout-box" v-if="lang == 'zh'">
          <p><a>&nbsp;</a></p>
          <p><a target="_blank" href="https://space.bilibili.com/424063928" rel="noopener noreferrer">bilibili</a></p>
          <p><a class="qrcode">抖音
              <img src="../../assets/img/douyin.png">
            </a></p>
        </div>
        <div class="horizontal-layout-box" v-if="lang == 'en'">
          <p><a>&nbsp;</a></p>
          <p><a target="_blank" href="https://space.bilibili.com/424063928" rel="noopener noreferrer">bilibili</a></p>
          <p><a class="qrcode">
              DouYin
              <img src="../../assets/img/douyin.png">
            </a></p>
        </div>
      </div>
    </div>
    <div class="base-row base-phone-box">
      <div class="base-col-4 tip-1">
        <div class="horizontal-tip-layout-box">
          <p v-if="lang == 'zh'">了解双碳目标，支持气候行动，</p>
          <p v-if="lang == 'zh'">共创碳中和美好未来</p>
        </div>
        <p v-if="lang == 'en'">
          Understand the dual carbon goals, support climate action, and create a
          carbon neutral future.
        </p>
      </div>
    </div>
    <div class="base-row base-phone-box horizontal-phone-box">
      <div class="base-col connect-box">
        <div class="horizontal-layout-box" v-if="lang == 'zh'">
          <p><a>气候行动周</a></p>
          <p><a target="_blank" href="https://www.wwfchina.org/about?id=40&type=1" rel="noopener noreferrer">隐私政策</a></p>
          <p><a target="_blank" href="https://www.wwfchina.org/about?id=38&type=1" rel="noopener noreferrer">联系我们</a></p>
        </div>
        <div class="horizontal-layout-box" v-if="lang == 'en'">
          <p><a>Climate Action Week</a></p>
          <p><a target="_blank" href="https://www.wwfchina.org/about?id=40&type=1" rel="noopener noreferrer">Privacy
              Policy</a></p>
          <p><a target="_blank" href="https://www.wwfchina.org/about?id=38&type=1" rel="noopener noreferrer">Contact
              us</a></p>
        </div>
      </div>
      <div class="base-col"></div>
      <div class="base-col connect-box">
        <div class="horizontal-layout-box" v-if="lang == 'zh'">
          <p><a>关注我们</a></p>
          <p><a target="_blank" href="https://weibo.com/p/1006061702771281" rel="noopener noreferrer">微博</a></p>
          <p><a class="qrcode">
              微信
              <img src="../../assets/img/weixin.png">
            </a></p>
        </div>
        <div class="horizontal-layout-box" v-if="lang == 'en'">
          <p><a>About us</a></p>
          <p><a target="_blank" href="https://weibo.com/p/1006061702771281" rel="noopener noreferrer">WeiBo</a></p>
          <p><a class="qrcode">
              WeChat
              <img src="../../assets/img/weixin.png">
            </a></p>
        </div>
      </div>
      <div class="base-col connect-box">
        <div class="horizontal-layout-box" v-if="lang == 'zh'">
          <p><a>&nbsp;</a></p>
          <p><a target="_blank" href="https://space.bilibili.com/424063928" rel="noopener noreferrer">bilibili</a></p>
          <p><a class="qrcode">抖音
              <img src="../../assets/img/douyin.png">
            </a></p>
        </div>
        <div class="horizontal-layout-box" v-if="lang == 'en'">
          <p><a>&nbsp;</a></p>
          <p><a target="_blank" href="https://space.bilibili.com/424063928" rel="noopener noreferrer">bilibili</a></p>
          <p><a class="qrcode">
              DouYin
              <img src="../../assets/img/douyin.png">
            </a></p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ccawpro-footer",
  computed: {
    ...mapState(["lang"]),
  },
};
</script>

<style lang="scss" scoped>
.qrcode {
  position: relative;
}

.qrcode img {
  position: absolute;
  height: 140px;
  width: 140px;
  top: -155px;
  right: -55px;
  transform-origin: bottom;
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.qrcode:hover img {
  transform: scale(1);
  opacity: 1;
}

.horizontal-phone-box {
  margin-top: 50px;
}

footer {
  padding-bottom: 11.25rem;
}

.footer-logo-box {
  width: auto;
  max-width: 367px;
  max-height: 80px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }
}

.base-phone-box {
  .footer-logo-box {
    width: auto;
    max-width: 294px;
    height: 64px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    img {
      display: block;
      height: 100%;
      max-width: 100%;
    }
  }

  .horizontal-layout-box {
    font-family: "fontMedium";
    font-size: 18px;
    line-height: 28px;
  }

  .horizontal-tip-layout-box {
    font-family: "fontNormal";
    font-size: 30px;
    line-height: 42px;
  }
}

.base-phone-box.footer-logo {
  margin-bottom: 40px;
}

.connect-box {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}

.tip-1 {
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
}

@media screen and (max-width: 1440) {
  .tip-1 {
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
  }

  .connect-box {
    font-size: 14px;
    line-height: 14px;
  }
}

.horizontal-layout-box {
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.spacing {
  margin-bottom: 12px;
}

.footer-logo {
  margin-bottom: 69px;
}

.logo {
  margin-top: 1rem;
  margin-bottom: 3.75rem;
}

.bottomtext {
  font-family: "fontNormal";
  font-size: 2rem;
  line-height: 2.875rem;
  color: #fff;
}

.bottomtext li:nth-child(1) {
  float: left;
  width: 25%;
  margin-right: 20%;
  font-family: "fontNormal";
  font-size: 2rem;
  line-height: 2.25rem;
  margin-left: 3.75rem;
  color: #fff;
}

.bottomtext li:nth-child(2) {
  float: left;
  width: 18%;
  font-family: "fontNormal";
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #fff;
}

.bottomtext li:nth-child(3) {
  float: left;
  width: 18%;
  font-family: "fontNormal";
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #fff;
}

.bottomtext li:nth-child(4) {
  float: left;
  width: 15%;
  font-family: "fontNormal";
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #fff;
}

.smallp {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
}

.footer-container {
  .commonhr1 {
    margin-top: 180px;
  }
}

/* 分割线样式 */
.commonhr1 {
  height: 0.125rem;
  background-color: rgb(125, 51, 255);
}

@media screen and (max-width: 1440px) {
  .bottomtext li:nth-child(1) {
    font-size: 1.5rem;
    margin-right: 18%;
  }

  .smallp a {
    font-size: 18px;
    color: #ffffff;
    font-weight: 400;
  }
}

@media screen and (max-width: 1024px) {

  // .bottomtext li,.smallp{
  //   width: 100% !important;
  //   margin: 0 !important;
  //   text-align: center !important;
  // }
  footer {
    padding-bottom: 4.75rem;

    .commonhr {}

    .logo {
      width: 14.8125rem;
      height: 4rem;
      margin-left: 1.75rem;
      margin-right: 0;
      margin-bottom: 1.75rem;
    }
  }

  .bottomtext {
    padding: 0 1.75rem;
  }

  .bottomtext li:nth-child(1) {
    width: 100%;
    margin-left: 0;
    line-height: 1.875rem;
    margin-bottom: 3.125rem;

    p {
      font-size: 1.8rem;
      line-height: 1.5;
      // max-width: 65%;
    }
  }

  .smallp {
    width: 33% !important;
  }
}
</style>
