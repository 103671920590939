import axios from "axios";

if (process.env.NODE_ENV == "development") {
    axios.defaults.baseURL = "/api";//提取公用ip:端口号
} else {
    axios.defaults.baseURL = "/";//提取公用ip:端口号
}

axios.interceptors.request.use(function (config) {//请求之前的拦截****************

    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {// 响应之前拦截*************
    if (response.data.code != 200) {
        console.log("请求有误");
        return false
    }
    return response.data;//过滤出所有接口的 data数据
}, function (error) {
    return Promise.reject(error);
});
export default axios;