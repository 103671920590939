import "@/assets/css/common.scss";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Vue from "vue";
import App from "./App.vue";
import axios from "./axios";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import  "amfe-flexible";
import './utils/rem'
Vue.prototype.$axios = axios;
// export const baseUrl = "http://120.46.133.173"
export const baseUrl = "http://120.24.86.154/";
export const imgUrl = "";
Vue.prototype.baseUrl = baseUrl;
Vue.prototype.imgUrl = imgUrl;
Vue.use(ElementUI);
Vue.config.productionTip = false;

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale:
    window.localStorage.getItem("user_lang") == null
      ? "cn"
      : window.localStorage.getItem("user_lang"), // 语言标识
  messages: {
    cn: require("./i18n/cn"), // 中文语言包
    en: require("./i18n/en"), // 英文语言包
  },
});

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
