<template>
  <div class="facon">
    <navigation></navigation>
    <!-- <keep-alive> -->
    <keep-alive include="Index,News,Activity,Database,Partner">
      <router-view />
    </keep-alive>
    <footers></footers>
  </div>
</template>
<script>
import footers from "@/components/Footers";
import navigation from "@/components/Navigation";
export default {
  name: "ccwapro-app",
  components: {
    navigation,
    footers,
  },
  data() {
    return {};
  },
  mounted() {
    
  },
  methods: {},
};
</script>

<style lang="scss">
.facon {
  position: relative;
}
</style>
