<template>
  <div>
    <section :class="[
      $route.path == '/index'
        ? 'base-container navigation-container'
        : 'base-container navigation-container base-other-container',
    ]">
      <div class="base-row navigation-menu-phone-box">
        <div class="base-col-3">
          <div class="navigation-logo-box">
            <router-link to="/">
              <img src="../../assets/img/logo.png" alt="" />
            </router-link>
          </div>
        </div>

        <div class="base-col">
          <img class="fr collepes coicon" @click="showmaskFun" src="../../assets/img/open.svg" alt="" />
        </div>
      </div>
      <div :class="`${$route.path == '/index'
        ? 'base-row navigation-menu-pc-box'
        : 'no-index-page-box base-row navigation-menu-pc-box'
        }`">
        <div class="base-col-2 center">
          <div class="navigation-logo-box ">
            <router-link to="/">
              <img src="../../assets/img/logo.png" alt="" />
            </router-link>
          </div>
        </div>

        <div class="base-col navigation-menu-pc-box"></div>
        <div class="base-col navigation-link-box center navigation-menu-pc-box">
          <nav>
            <router-link to="/news">{{
              lang == "zh" ? "新闻" : "News"
            }}</router-link>
          </nav>
        </div>
        <div class="base-col navigation-link-box center navigation-menu-pc-box">
          <nav>
            <router-link to="/project">{{
              lang == "zh" ? "活动" : "Project"
            }}</router-link>
          </nav>
        </div>
        <div class="base-col navigation-link-box center navigation-menu-pc-box">
          <nav>
            <router-link to="/community">{{
              lang == "zh" ? "资料库" : "Community"
            }}</router-link>
          </nav>
        </div>
        <div class="base-col navigation-link-box center navigation-menu-pc-box">
          <nav>
            <router-link to="/sponsor">{{
              lang == "zh" ? "合作伙伴" : "Sponsor"
            }}</router-link>
          </nav>
        </div>
        <div class="base-col navigation-menu-pc-box"></div>
        <!-- <div
        class="base-col text-align-end navigation-link-box center navigation-menu-pc-box"
        style="justify-content: flex-end"
      >
        <nav>
          <a @click="changValFun(lang == 'zh' ? 'en' : 'zh')" to="/">{{
            lang == "zh" ? "EN" : "中"
          }}</a>
        </nav>
      </div> -->
      </div>
      <div v-if="$route.path != '/index'" class="hr"></div>
    </section>

    <!-- 下面是移动端适配 -->
    <section class="base-container maskdiv" :style="{ left: left }">
      <div class="clearfix  center base-row mask-header-box">
        <router-link to="/" class="fl base-col-3">
          <img class="navigation-logo-box fl" @click="changValFunLogo" src="../../assets/img/logo.png" alt="" />
        </router-link>
        <div class="base-col">
          <img class="closeicon fr " @click="left = '100%'" src="../../assets/img/close.svg" alt="" />
        </div>
      </div>
      <!-- 下面是导航跳转 -->
      <div class="base-row">
        <section class="base-col-4 commonhr minihr maskdiv-commonhr"></section>
      </div>
      <section class="base-row">
        <div class="a base-col-4" to="/news" @click="hiddenmask('/news')">
          {{ lang == "zh" ? "新闻" : "News" }}
        </div>
      </section>

      <div class="base-row">
        <section class="base-col-4 commonhr minihr maskdiv-commonhr"></section>
      </div>
      <section class="base-row">
        <div class="a base-col-4" to="/project" @click="hiddenmask('/project')">
          {{ lang == "zh" ? "活动" : "Activity" }}
        </div>
      </section>
      <div class="base-row">
        <section class="base-col-4 commonhr minihr maskdiv-commonhr"></section>
      </div>
      <section class="base-row">
        <div class="a base-col-4" to="/community" @click="hiddenmask('/community')">
          {{ lang == "zh" ? "资料库" : "Database" }}
        </div>
      </section>
      <div class="base-row">
        <section class="base-col-4 commonhr minihr maskdiv-commonhr"></section>
      </div>
      <section class="base-row">
        <div class="a base-col-4" to="/sponsor" @click="hiddenmask('/sponsor')">
          {{ lang == "zh" ? "合作伙伴" : "Partner" }}
        </div>
      </section>
      <!-- <div class="bottomdiv">
        <a @click="changValFun('en')" v-if="lang == 'zh'">EN</a>
        <a @click="changValFun('zh')" v-if="lang == 'en'">中</a>
      </div> -->
    </section>
  </div>
</template>

<script>
import "./index.scss";
import { mapMutations, mapState } from "vuex";
export default {
  name: "ccawpro-navigation",
  data() {
    return {
      size: "mini",
      showmask: false,
      options: [
        {
          value: "zh",
          label: "中文",
        },
        {
          value: "en",
          label: "English",
        },
      ],
      left: "100%",
      // value: "zh",
    };
  },
  mounted() {
    var obj = JSON.parse(sessionStorage.getItem("store"));
    this.value = obj ? obj.lang : "zh";
  },
  computed: {
    ...mapState(["lang"]),
  },
  methods: {
    showmaskFun() {
      this.left = "0";
    },
    ...mapMutations(["changLang"]),
    changVal() {
      // console.log(this.value);
      // this.changLang(val);
    },
    changValFun(val) {
      console.log(val);
      this.showmask = false;
      this.left = "100%";
      this.changLang(val);
    },
    changValFunLogo() {
      this.showmask = false;
      this.left = "100%";
    },

    hiddenmask(url) {
      console.log("go");
      this.left = "100%";
      this.$router.push({
        path: url,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.no-index-page-box {
  a {
    color: #ffffff !important;
  }
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.navcon {
  position: absolute;
  width: 100%;
  // background: #5000dc;
  // background: rgba(80, 0, 200, 0.5);
  // background: red;
  top: 2.5rem;
  // left: 60px;
  // padding: 0 3%;
  // padding-top: 40px;
  z-index: 2;
}

.navconcommon {
  padding-top: 2.5rem;
}

.hr {
  height: 0.125rem;
  background-color: rgb(125, 51, 255);
  margin-top: 7.5rem;
}

nav a {
  font-size: 28px;
  color: #000;
  line-height: 4rem;
}

.aspicle {
  // margin-right: 205px;
}

@media screen and (max-width: 1440px) {
  nav a {
    font-size: 20px;
  }
}

.el-select--mini {
  width: 5.625rem !important;

  .el-input__inner {
    padding: 0 !important;
  }
}

// ***************************.closeicon

.collepes {
  display: none;
}

.navigation-menu-phone-box {
  display: none;

  .coicon {
    width: 54px;
    height: 54px;
  }
}

.closeicon {
  width: 54px;
  height: 54px;
}

@media screen and (max-width: 750px) {
  .navigation-menu-phone-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .navigation-menu-pc-box {
    display: none;
  }

  .hr {
    height: 0.125rem;
    background-color: rgb(125, 51, 255);
    margin-top: 126px;
  }

  .navconcommon {
    padding-top: 1.875rem;
  }

  .navcon {
    top: 1.875rem;
  }

  nav {
    display: none;
  }

  .collepes {
    display: block;
  }
}


.maskdiv .commonhr {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

// 下面是适配展开导航样式
.maskdiv {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #ffffff;
  font-size: 46px;
  line-height: 54px;
  font-family: 'fontRegular';
  transition: all 0.4s;
  background: rgb(46, 0, 128);




  .navulcon .a {
    color: #ffffff;
    display: block;
    height: 4rem;
    line-height: 4rem;
    border-top: 0.125rem solid white !important;
    font-size: 1.4375rem;
  }

  .bottomdiv {
    position: fixed;
    z-index: 2;
    width: 90%;
    bottom: 0;

    a {
      display: block;
      border-top: 0.125rem solid white !important;
      // padding: 40px 0;
      height: 4rem;
      line-height: 3.75rem;
      font-size: 1.4375rem;
      color: #000;
    }
  }
}</style>
